.profile_changePicture {
  font-size: 14px;
  width: 80%;
  text-align: center;
  color: blue;
  cursor: pointer;
}

.profile_form {
  display: flex;
  justify-content: center;
  margin: 15px 0;
}
