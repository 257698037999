.css-diiaic-MuiInputBase-root-MuiInput-root {
  margin: 0 15px 0 0;
}

.displayForm {
  display: flex;
}

.displayInput {
  display: none;
}

.addSourceInput {
  display: none;
}

.selectImgLabel {
  background-color: #393652;
  color: #eee;
  padding: 20px 40px;
  border-radius: 5px;
  font-weight: 700;
  font-size: 17px;
  cursor: pointer;
}

.uploadApp_selectImgLabel {
  /* background-color: #393652; */
  color: #eee;
  /* padding: 20px 40px; */
  border-radius: 5px;
  font-weight: 700;
  font-size: 17px;
  cursor: pointer;
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 25px;
}

.displayNone {
  display: none !important;
}

.uploadApp_selectImgLabel:hover {
  background-color: rgb(57 54 82 / 0.5);
}

.addSource {
  color: #eee;
  font-weight: 700;
  font-size: 17px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.removeAddSource {
  display: none;
}

.removeDisplayPic {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
}

.hideSelectImgLabel {
  display: none;
}

.hideDisplayImgContainer {
  display: none;
}

.img-preview {
  width: 100%;
  height: 300px;
  /* margin: 0 auto; */
  overflow: hidden;
  background-color: grey;
  border-radius: 10px;
}

.img-preview-hidden {
  display: none;
}

.adjustButton {
  background-color: white;
  padding: 25px;
  border-radius: 5px;
}

.hideAdjustButton {
  display: none;
}

.previewContainer {
  display: flex;
  width: 100%;
  padding: 25px;
  justify-content: space-around;
  background-color: yellow;
}

.hidePreviewContainer {
  display: none;
}

.thumbnailContainer {
  width: 100% !important;
  margin: 25px 0 0 0;
  border-radius: 5px;
}

.hideThumbnailContainer {
  display: none;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: grey !important;
  font-size: 17px !important;
}

.css-1480iag-MuiInputBase-root-MuiInput-root:after {
  border-bottom: 1px solid #cbcbcb !important;
}

.css-wx9ddr-MuiInputBase-root-MuiInput-root:after {
  border-bottom: 1px solid #cbcbcb !important;
}

.css-1480iag-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid #cbcbcb !important;
}

.css-wx9ddr-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid #cbcbcb !important;
}
